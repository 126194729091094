/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BeneficialOwner,
  BeneficialOwnershipReport,
  CompanyApplicant,
  PaginatedBeneficialOwnerList,
  PaginatedBeneficialOwnershipReportList,
  PaginatedCompanyApplicantList,
  PatchedBeneficialOwner,
  PatchedBeneficialOwnershipReport,
  PatchedCompanyApplicant,
} from '../models/index';
import {
    BeneficialOwnerFromJSON,
    BeneficialOwnerToJSON,
    BeneficialOwnershipReportFromJSON,
    BeneficialOwnershipReportToJSON,
    CompanyApplicantFromJSON,
    CompanyApplicantToJSON,
    PaginatedBeneficialOwnerListFromJSON,
    PaginatedBeneficialOwnerListToJSON,
    PaginatedBeneficialOwnershipReportListFromJSON,
    PaginatedBeneficialOwnershipReportListToJSON,
    PaginatedCompanyApplicantListFromJSON,
    PaginatedCompanyApplicantListToJSON,
    PatchedBeneficialOwnerFromJSON,
    PatchedBeneficialOwnerToJSON,
    PatchedBeneficialOwnershipReportFromJSON,
    PatchedBeneficialOwnershipReportToJSON,
    PatchedCompanyApplicantFromJSON,
    PatchedCompanyApplicantToJSON,
} from '../models/index';

export interface BeneficialOwnersCreateRequest {
    teamSlug: string;
    beneficialOwner: Omit<BeneficialOwner, 'id'|'created_at'|'updated_at'>;
}

export interface BeneficialOwnersDestroyRequest {
    id: number;
    teamSlug: string;
}

export interface BeneficialOwnersListRequest {
    teamSlug: string;
    page?: number;
}

export interface BeneficialOwnersPartialUpdateRequest {
    id: number;
    teamSlug: string;
    patchedBeneficialOwner?: Omit<PatchedBeneficialOwner, 'id'|'created_at'|'updated_at'>;
}

export interface BeneficialOwnersRetrieveRequest {
    id: number;
    teamSlug: string;
}

export interface BeneficialOwnersUpdateRequest {
    id: number;
    teamSlug: string;
    beneficialOwner: Omit<BeneficialOwner, 'id'|'created_at'|'updated_at'>;
}

export interface BoiReportsCreateRequest {
    teamSlug: string;
    beneficialOwnershipReport: Omit<BeneficialOwnershipReport, 'id'|'filing_status_display'|'jurisdiction_display'|'payment_status_display'|'report_name_display'|'type_of_filing_display'|'tax_id_type_display'|'created_at'|'updated_at'|'team'>;
}

export interface BoiReportsDestroyRequest {
    id: number;
    teamSlug: string;
}

export interface BoiReportsListRequest {
    teamSlug: string;
    page?: number;
}

export interface BoiReportsPartialUpdateRequest {
    id: number;
    teamSlug: string;
    patchedBeneficialOwnershipReport?: Omit<PatchedBeneficialOwnershipReport, 'id'|'filing_status_display'|'jurisdiction_display'|'payment_status_display'|'report_name_display'|'type_of_filing_display'|'tax_id_type_display'|'created_at'|'updated_at'|'team'>;
}

export interface BoiReportsRetrieveRequest {
    id: number;
    teamSlug: string;
}

export interface BoiReportsUpdateRequest {
    id: number;
    teamSlug: string;
    beneficialOwnershipReport: Omit<BeneficialOwnershipReport, 'id'|'filing_status_display'|'jurisdiction_display'|'payment_status_display'|'report_name_display'|'type_of_filing_display'|'tax_id_type_display'|'created_at'|'updated_at'|'team'>;
}

export interface CompanyApplicantsCreateRequest {
    teamSlug: string;
    companyApplicant: Omit<CompanyApplicant, 'id'|'created_at'|'updated_at'>;
}

export interface CompanyApplicantsDestroyRequest {
    id: number;
    teamSlug: string;
}

export interface CompanyApplicantsListRequest {
    teamSlug: string;
    page?: number;
}

export interface CompanyApplicantsPartialUpdateRequest {
    id: number;
    teamSlug: string;
    patchedCompanyApplicant?: Omit<PatchedCompanyApplicant, 'id'|'created_at'|'updated_at'>;
}

export interface CompanyApplicantsRetrieveRequest {
    id: number;
    teamSlug: string;
}

export interface CompanyApplicantsUpdateRequest {
    id: number;
    teamSlug: string;
    companyApplicant: Omit<CompanyApplicant, 'id'|'created_at'|'updated_at'>;
}

export interface TaskStatusRequest {
    taskId: string;
    teamSlug: string;
}

export interface UploadBeneficialOwnerRequest {
    filingId: number;
    teamSlug: string;
    beneficialOwnershipReport: number;
    idImage: Blob;
    idType: UploadBeneficialOwnerIdTypeEnum;
}

export interface UploadBoiSubmissionDetailsRequest {
    filingId: number;
    teamSlug: string;
    beneficialOwnershipReport: number;
    submissionTrackingId?: string;
    boirId?: string;
    submissionTranscript?: Blob;
    receivedTimestamp?: Date;
    fincenId?: string;
}

/**
 * BoiReportsApi - interface
 * 
 * @export
 * @interface BoiReportsApiInterface
 */
export interface BoiReportsApiInterface {
    /**
     * 
     * @param {string} teamSlug 
     * @param {BeneficialOwner} beneficialOwner 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoiReportsApiInterface
     */
    beneficialOwnersCreateRaw(requestParameters: BeneficialOwnersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BeneficialOwner>>;

    /**
     */
    beneficialOwnersCreate(requestParameters: BeneficialOwnersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BeneficialOwner>;

    /**
     * 
     * @param {number} id A unique integer value identifying this Beneficial Owner.
     * @param {string} teamSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoiReportsApiInterface
     */
    beneficialOwnersDestroyRaw(requestParameters: BeneficialOwnersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    beneficialOwnersDestroy(requestParameters: BeneficialOwnersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} teamSlug 
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoiReportsApiInterface
     */
    beneficialOwnersListRaw(requestParameters: BeneficialOwnersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedBeneficialOwnerList>>;

    /**
     */
    beneficialOwnersList(requestParameters: BeneficialOwnersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedBeneficialOwnerList>;

    /**
     * 
     * @param {number} id A unique integer value identifying this Beneficial Owner.
     * @param {string} teamSlug 
     * @param {PatchedBeneficialOwner} [patchedBeneficialOwner] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoiReportsApiInterface
     */
    beneficialOwnersPartialUpdateRaw(requestParameters: BeneficialOwnersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BeneficialOwner>>;

    /**
     */
    beneficialOwnersPartialUpdate(requestParameters: BeneficialOwnersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BeneficialOwner>;

    /**
     * 
     * @param {number} id A unique integer value identifying this Beneficial Owner.
     * @param {string} teamSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoiReportsApiInterface
     */
    beneficialOwnersRetrieveRaw(requestParameters: BeneficialOwnersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BeneficialOwner>>;

    /**
     */
    beneficialOwnersRetrieve(requestParameters: BeneficialOwnersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BeneficialOwner>;

    /**
     * 
     * @param {number} id A unique integer value identifying this Beneficial Owner.
     * @param {string} teamSlug 
     * @param {BeneficialOwner} beneficialOwner 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoiReportsApiInterface
     */
    beneficialOwnersUpdateRaw(requestParameters: BeneficialOwnersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BeneficialOwner>>;

    /**
     */
    beneficialOwnersUpdate(requestParameters: BeneficialOwnersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BeneficialOwner>;

    /**
     * 
     * @param {string} teamSlug 
     * @param {BeneficialOwnershipReport} beneficialOwnershipReport 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoiReportsApiInterface
     */
    boiReportsCreateRaw(requestParameters: BoiReportsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BeneficialOwnershipReport>>;

    /**
     */
    boiReportsCreate(requestParameters: BoiReportsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BeneficialOwnershipReport>;

    /**
     * 
     * @param {number} id A unique integer value identifying this Beneficial Ownership Information Report.
     * @param {string} teamSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoiReportsApiInterface
     */
    boiReportsDestroyRaw(requestParameters: BoiReportsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    boiReportsDestroy(requestParameters: BoiReportsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} teamSlug 
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoiReportsApiInterface
     */
    boiReportsListRaw(requestParameters: BoiReportsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedBeneficialOwnershipReportList>>;

    /**
     */
    boiReportsList(requestParameters: BoiReportsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedBeneficialOwnershipReportList>;

    /**
     * 
     * @param {number} id A unique integer value identifying this Beneficial Ownership Information Report.
     * @param {string} teamSlug 
     * @param {PatchedBeneficialOwnershipReport} [patchedBeneficialOwnershipReport] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoiReportsApiInterface
     */
    boiReportsPartialUpdateRaw(requestParameters: BoiReportsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BeneficialOwnershipReport>>;

    /**
     */
    boiReportsPartialUpdate(requestParameters: BoiReportsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BeneficialOwnershipReport>;

    /**
     * 
     * @param {number} id A unique integer value identifying this Beneficial Ownership Information Report.
     * @param {string} teamSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoiReportsApiInterface
     */
    boiReportsRetrieveRaw(requestParameters: BoiReportsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BeneficialOwnershipReport>>;

    /**
     */
    boiReportsRetrieve(requestParameters: BoiReportsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BeneficialOwnershipReport>;

    /**
     * 
     * @param {number} id A unique integer value identifying this Beneficial Ownership Information Report.
     * @param {string} teamSlug 
     * @param {BeneficialOwnershipReport} beneficialOwnershipReport 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoiReportsApiInterface
     */
    boiReportsUpdateRaw(requestParameters: BoiReportsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BeneficialOwnershipReport>>;

    /**
     */
    boiReportsUpdate(requestParameters: BoiReportsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BeneficialOwnershipReport>;

    /**
     * 
     * @param {string} teamSlug 
     * @param {CompanyApplicant} companyApplicant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoiReportsApiInterface
     */
    companyApplicantsCreateRaw(requestParameters: CompanyApplicantsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyApplicant>>;

    /**
     */
    companyApplicantsCreate(requestParameters: CompanyApplicantsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyApplicant>;

    /**
     * 
     * @param {number} id A unique integer value identifying this Company Applicant.
     * @param {string} teamSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoiReportsApiInterface
     */
    companyApplicantsDestroyRaw(requestParameters: CompanyApplicantsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    companyApplicantsDestroy(requestParameters: CompanyApplicantsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} teamSlug 
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoiReportsApiInterface
     */
    companyApplicantsListRaw(requestParameters: CompanyApplicantsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedCompanyApplicantList>>;

    /**
     */
    companyApplicantsList(requestParameters: CompanyApplicantsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedCompanyApplicantList>;

    /**
     * 
     * @param {number} id A unique integer value identifying this Company Applicant.
     * @param {string} teamSlug 
     * @param {PatchedCompanyApplicant} [patchedCompanyApplicant] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoiReportsApiInterface
     */
    companyApplicantsPartialUpdateRaw(requestParameters: CompanyApplicantsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyApplicant>>;

    /**
     */
    companyApplicantsPartialUpdate(requestParameters: CompanyApplicantsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyApplicant>;

    /**
     * 
     * @param {number} id A unique integer value identifying this Company Applicant.
     * @param {string} teamSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoiReportsApiInterface
     */
    companyApplicantsRetrieveRaw(requestParameters: CompanyApplicantsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyApplicant>>;

    /**
     */
    companyApplicantsRetrieve(requestParameters: CompanyApplicantsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyApplicant>;

    /**
     * 
     * @param {number} id A unique integer value identifying this Company Applicant.
     * @param {string} teamSlug 
     * @param {CompanyApplicant} companyApplicant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoiReportsApiInterface
     */
    companyApplicantsUpdateRaw(requestParameters: CompanyApplicantsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyApplicant>>;

    /**
     */
    companyApplicantsUpdate(requestParameters: CompanyApplicantsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyApplicant>;

    /**
     * Get the status of a Celery task
     * @param {string} taskId Celery task ID
     * @param {string} teamSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoiReportsApiInterface
     */
    taskStatusRaw(requestParameters: TaskStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: any; }>>;

    /**
     * Get the status of a Celery task
     */
    taskStatus(requestParameters: TaskStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: any; }>;

    /**
     * Upload a new Beneficial Owner image and initiate processing
     * @param {number} filingId 
     * @param {string} teamSlug 
     * @param {number} beneficialOwnershipReport 
     * @param {Blob} idImage 
     * @param {string} idType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoiReportsApiInterface
     */
    uploadBeneficialOwnerRaw(requestParameters: UploadBeneficialOwnerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: any; }>>;

    /**
     * Upload a new Beneficial Owner image and initiate processing
     */
    uploadBeneficialOwner(requestParameters: UploadBeneficialOwnerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: any; }>;

    /**
     * Upload BOI report details after processsing has completed
     * @param {number} filingId 
     * @param {string} teamSlug 
     * @param {number} beneficialOwnershipReport 
     * @param {string} [submissionTrackingId] 
     * @param {string} [boirId] 
     * @param {Blob} [submissionTranscript] 
     * @param {Date} [receivedTimestamp] 
     * @param {string} [fincenId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoiReportsApiInterface
     */
    uploadBoiSubmissionDetailsRaw(requestParameters: UploadBoiSubmissionDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: any; }>>;

    /**
     * Upload BOI report details after processsing has completed
     */
    uploadBoiSubmissionDetails(requestParameters: UploadBoiSubmissionDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: any; }>;

}

/**
 * 
 */
export class BoiReportsApi extends runtime.BaseAPI implements BoiReportsApiInterface {

    /**
     */
    async beneficialOwnersCreateRaw(requestParameters: BeneficialOwnersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BeneficialOwner>> {
        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling beneficialOwnersCreate().'
            );
        }

        if (requestParameters['beneficialOwner'] == null) {
            throw new runtime.RequiredError(
                'beneficialOwner',
                'Required parameter "beneficialOwner" was null or undefined when calling beneficialOwnersCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/boi-reports/api/beneficial-owners/`.replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BeneficialOwnerToJSON(requestParameters['beneficialOwner']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BeneficialOwnerFromJSON(jsonValue));
    }

    /**
     */
    async beneficialOwnersCreate(requestParameters: BeneficialOwnersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BeneficialOwner> {
        const response = await this.beneficialOwnersCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async beneficialOwnersDestroyRaw(requestParameters: BeneficialOwnersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling beneficialOwnersDestroy().'
            );
        }

        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling beneficialOwnersDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/boi-reports/api/beneficial-owners/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async beneficialOwnersDestroy(requestParameters: BeneficialOwnersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.beneficialOwnersDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async beneficialOwnersListRaw(requestParameters: BeneficialOwnersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedBeneficialOwnerList>> {
        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling beneficialOwnersList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/boi-reports/api/beneficial-owners/`.replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedBeneficialOwnerListFromJSON(jsonValue));
    }

    /**
     */
    async beneficialOwnersList(requestParameters: BeneficialOwnersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedBeneficialOwnerList> {
        const response = await this.beneficialOwnersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async beneficialOwnersPartialUpdateRaw(requestParameters: BeneficialOwnersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BeneficialOwner>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling beneficialOwnersPartialUpdate().'
            );
        }

        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling beneficialOwnersPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/boi-reports/api/beneficial-owners/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedBeneficialOwnerToJSON(requestParameters['patchedBeneficialOwner']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BeneficialOwnerFromJSON(jsonValue));
    }

    /**
     */
    async beneficialOwnersPartialUpdate(requestParameters: BeneficialOwnersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BeneficialOwner> {
        const response = await this.beneficialOwnersPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async beneficialOwnersRetrieveRaw(requestParameters: BeneficialOwnersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BeneficialOwner>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling beneficialOwnersRetrieve().'
            );
        }

        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling beneficialOwnersRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/boi-reports/api/beneficial-owners/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BeneficialOwnerFromJSON(jsonValue));
    }

    /**
     */
    async beneficialOwnersRetrieve(requestParameters: BeneficialOwnersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BeneficialOwner> {
        const response = await this.beneficialOwnersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async beneficialOwnersUpdateRaw(requestParameters: BeneficialOwnersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BeneficialOwner>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling beneficialOwnersUpdate().'
            );
        }

        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling beneficialOwnersUpdate().'
            );
        }

        if (requestParameters['beneficialOwner'] == null) {
            throw new runtime.RequiredError(
                'beneficialOwner',
                'Required parameter "beneficialOwner" was null or undefined when calling beneficialOwnersUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/boi-reports/api/beneficial-owners/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BeneficialOwnerToJSON(requestParameters['beneficialOwner']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BeneficialOwnerFromJSON(jsonValue));
    }

    /**
     */
    async beneficialOwnersUpdate(requestParameters: BeneficialOwnersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BeneficialOwner> {
        const response = await this.beneficialOwnersUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async boiReportsCreateRaw(requestParameters: BoiReportsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BeneficialOwnershipReport>> {
        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling boiReportsCreate().'
            );
        }

        if (requestParameters['beneficialOwnershipReport'] == null) {
            throw new runtime.RequiredError(
                'beneficialOwnershipReport',
                'Required parameter "beneficialOwnershipReport" was null or undefined when calling boiReportsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/boi-reports/api/beneficial-ownership-reports/`.replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BeneficialOwnershipReportToJSON(requestParameters['beneficialOwnershipReport']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BeneficialOwnershipReportFromJSON(jsonValue));
    }

    /**
     */
    async boiReportsCreate(requestParameters: BoiReportsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BeneficialOwnershipReport> {
        const response = await this.boiReportsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async boiReportsDestroyRaw(requestParameters: BoiReportsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling boiReportsDestroy().'
            );
        }

        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling boiReportsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/boi-reports/api/beneficial-ownership-reports/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async boiReportsDestroy(requestParameters: BoiReportsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.boiReportsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async boiReportsListRaw(requestParameters: BoiReportsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedBeneficialOwnershipReportList>> {
        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling boiReportsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/boi-reports/api/beneficial-ownership-reports/`.replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedBeneficialOwnershipReportListFromJSON(jsonValue));
    }

    /**
     */
    async boiReportsList(requestParameters: BoiReportsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedBeneficialOwnershipReportList> {
        const response = await this.boiReportsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async boiReportsPartialUpdateRaw(requestParameters: BoiReportsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BeneficialOwnershipReport>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling boiReportsPartialUpdate().'
            );
        }

        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling boiReportsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/boi-reports/api/beneficial-ownership-reports/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedBeneficialOwnershipReportToJSON(requestParameters['patchedBeneficialOwnershipReport']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BeneficialOwnershipReportFromJSON(jsonValue));
    }

    /**
     */
    async boiReportsPartialUpdate(requestParameters: BoiReportsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BeneficialOwnershipReport> {
        const response = await this.boiReportsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async boiReportsRetrieveRaw(requestParameters: BoiReportsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BeneficialOwnershipReport>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling boiReportsRetrieve().'
            );
        }

        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling boiReportsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/boi-reports/api/beneficial-ownership-reports/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BeneficialOwnershipReportFromJSON(jsonValue));
    }

    /**
     */
    async boiReportsRetrieve(requestParameters: BoiReportsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BeneficialOwnershipReport> {
        const response = await this.boiReportsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async boiReportsUpdateRaw(requestParameters: BoiReportsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BeneficialOwnershipReport>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling boiReportsUpdate().'
            );
        }

        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling boiReportsUpdate().'
            );
        }

        if (requestParameters['beneficialOwnershipReport'] == null) {
            throw new runtime.RequiredError(
                'beneficialOwnershipReport',
                'Required parameter "beneficialOwnershipReport" was null or undefined when calling boiReportsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/boi-reports/api/beneficial-ownership-reports/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BeneficialOwnershipReportToJSON(requestParameters['beneficialOwnershipReport']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BeneficialOwnershipReportFromJSON(jsonValue));
    }

    /**
     */
    async boiReportsUpdate(requestParameters: BoiReportsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BeneficialOwnershipReport> {
        const response = await this.boiReportsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async companyApplicantsCreateRaw(requestParameters: CompanyApplicantsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyApplicant>> {
        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling companyApplicantsCreate().'
            );
        }

        if (requestParameters['companyApplicant'] == null) {
            throw new runtime.RequiredError(
                'companyApplicant',
                'Required parameter "companyApplicant" was null or undefined when calling companyApplicantsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/boi-reports/api/company-applicants/`.replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyApplicantToJSON(requestParameters['companyApplicant']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyApplicantFromJSON(jsonValue));
    }

    /**
     */
    async companyApplicantsCreate(requestParameters: CompanyApplicantsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyApplicant> {
        const response = await this.companyApplicantsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async companyApplicantsDestroyRaw(requestParameters: CompanyApplicantsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling companyApplicantsDestroy().'
            );
        }

        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling companyApplicantsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/boi-reports/api/company-applicants/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async companyApplicantsDestroy(requestParameters: CompanyApplicantsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.companyApplicantsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async companyApplicantsListRaw(requestParameters: CompanyApplicantsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedCompanyApplicantList>> {
        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling companyApplicantsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/boi-reports/api/company-applicants/`.replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedCompanyApplicantListFromJSON(jsonValue));
    }

    /**
     */
    async companyApplicantsList(requestParameters: CompanyApplicantsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedCompanyApplicantList> {
        const response = await this.companyApplicantsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async companyApplicantsPartialUpdateRaw(requestParameters: CompanyApplicantsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyApplicant>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling companyApplicantsPartialUpdate().'
            );
        }

        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling companyApplicantsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/boi-reports/api/company-applicants/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedCompanyApplicantToJSON(requestParameters['patchedCompanyApplicant']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyApplicantFromJSON(jsonValue));
    }

    /**
     */
    async companyApplicantsPartialUpdate(requestParameters: CompanyApplicantsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyApplicant> {
        const response = await this.companyApplicantsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async companyApplicantsRetrieveRaw(requestParameters: CompanyApplicantsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyApplicant>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling companyApplicantsRetrieve().'
            );
        }

        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling companyApplicantsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/boi-reports/api/company-applicants/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyApplicantFromJSON(jsonValue));
    }

    /**
     */
    async companyApplicantsRetrieve(requestParameters: CompanyApplicantsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyApplicant> {
        const response = await this.companyApplicantsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async companyApplicantsUpdateRaw(requestParameters: CompanyApplicantsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyApplicant>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling companyApplicantsUpdate().'
            );
        }

        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling companyApplicantsUpdate().'
            );
        }

        if (requestParameters['companyApplicant'] == null) {
            throw new runtime.RequiredError(
                'companyApplicant',
                'Required parameter "companyApplicant" was null or undefined when calling companyApplicantsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/boi-reports/api/company-applicants/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyApplicantToJSON(requestParameters['companyApplicant']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyApplicantFromJSON(jsonValue));
    }

    /**
     */
    async companyApplicantsUpdate(requestParameters: CompanyApplicantsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyApplicant> {
        const response = await this.companyApplicantsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the status of a Celery task
     */
    async taskStatusRaw(requestParameters: TaskStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: any; }>> {
        if (requestParameters['taskId'] == null) {
            throw new runtime.RequiredError(
                'taskId',
                'Required parameter "taskId" was null or undefined when calling taskStatus().'
            );
        }

        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling taskStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/boi-reports/task_status/{task_id}/`.replace(`{${"task_id"}}`, encodeURIComponent(String(requestParameters['taskId']))).replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get the status of a Celery task
     */
    async taskStatus(requestParameters: TaskStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: any; }> {
        const response = await this.taskStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a new Beneficial Owner image and initiate processing
     */
    async uploadBeneficialOwnerRaw(requestParameters: UploadBeneficialOwnerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: any; }>> {
        if (requestParameters['filingId'] == null) {
            throw new runtime.RequiredError(
                'filingId',
                'Required parameter "filingId" was null or undefined when calling uploadBeneficialOwner().'
            );
        }

        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling uploadBeneficialOwner().'
            );
        }

        if (requestParameters['beneficialOwnershipReport'] == null) {
            throw new runtime.RequiredError(
                'beneficialOwnershipReport',
                'Required parameter "beneficialOwnershipReport" was null or undefined when calling uploadBeneficialOwner().'
            );
        }

        if (requestParameters['idImage'] == null) {
            throw new runtime.RequiredError(
                'idImage',
                'Required parameter "idImage" was null or undefined when calling uploadBeneficialOwner().'
            );
        }

        if (requestParameters['idType'] == null) {
            throw new runtime.RequiredError(
                'idType',
                'Required parameter "idType" was null or undefined when calling uploadBeneficialOwner().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['beneficialOwnershipReport'] != null) {
            formParams.append('beneficial_ownership_report', requestParameters['beneficialOwnershipReport'] as any);
        }

        if (requestParameters['idImage'] != null) {
            formParams.append('id_image', requestParameters['idImage'] as any);
        }

        if (requestParameters['idType'] != null) {
            formParams.append('id_type', requestParameters['idType'] as any);
        }

        const response = await this.request({
            path: `/a/{team_slug}/boi-reports/{filing_id}/api/upload_beneficial_owner_id/`.replace(`{${"filing_id"}}`, encodeURIComponent(String(requestParameters['filingId']))).replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Upload a new Beneficial Owner image and initiate processing
     */
    async uploadBeneficialOwner(requestParameters: UploadBeneficialOwnerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: any; }> {
        const response = await this.uploadBeneficialOwnerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload BOI report details after processsing has completed
     */
    async uploadBoiSubmissionDetailsRaw(requestParameters: UploadBoiSubmissionDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: any; }>> {
        if (requestParameters['filingId'] == null) {
            throw new runtime.RequiredError(
                'filingId',
                'Required parameter "filingId" was null or undefined when calling uploadBoiSubmissionDetails().'
            );
        }

        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling uploadBoiSubmissionDetails().'
            );
        }

        if (requestParameters['beneficialOwnershipReport'] == null) {
            throw new runtime.RequiredError(
                'beneficialOwnershipReport',
                'Required parameter "beneficialOwnershipReport" was null or undefined when calling uploadBoiSubmissionDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['beneficialOwnershipReport'] != null) {
            formParams.append('beneficial_ownership_report', requestParameters['beneficialOwnershipReport'] as any);
        }

        if (requestParameters['submissionTrackingId'] != null) {
            formParams.append('submission_tracking_id', requestParameters['submissionTrackingId'] as any);
        }

        if (requestParameters['boirId'] != null) {
            formParams.append('boir_id', requestParameters['boirId'] as any);
        }

        if (requestParameters['submissionTranscript'] != null) {
            formParams.append('submission_transcript', requestParameters['submissionTranscript'] as any);
        }

        if (requestParameters['receivedTimestamp'] != null) {
            formParams.append('received_timestamp', (requestParameters['receivedTimestamp'] as any).toISOString());
        }

        if (requestParameters['fincenId'] != null) {
            formParams.append('fincen_id', requestParameters['fincenId'] as any);
        }

        const response = await this.request({
            path: `/a/{team_slug}/boi-reports/{filing_id}/api/upload_boi_submission_details/`.replace(`{${"filing_id"}}`, encodeURIComponent(String(requestParameters['filingId']))).replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Upload BOI report details after processsing has completed
     */
    async uploadBoiSubmissionDetails(requestParameters: UploadBoiSubmissionDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: any; }> {
        const response = await this.uploadBoiSubmissionDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
  * @export
  * @enum {string}
  */
export enum UploadBeneficialOwnerIdTypeEnum {
    UsPassport = 'us_passport',
    StateDriversLicense = 'state_drivers_license',
    StateIdCard = 'state_id_card',
    ForeignPassport = 'foreign_passport'
}
